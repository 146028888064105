
.container {
  margin: 2rem 0 3rem 0;
  min-height: 9em;
}

.ticketTable {
  width: 100%;
  border-collapse: collapse;
}


.ticketTable tbody {
  font-size: 1rem;
  color: #494949;
}

.ticketTable td {
  /*padding: 1rem 0;*/
  font-size: 0.9em;
  border-bottom: 1px solid #ddd;
}


.noTickets {
  margin: 5rem 0;
}

.ticketTable tbody {
  font-size: 1rem;
  color: #494949;
}

.headCell {
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 0;
  background: #f1f1f1;
  padding: 1rem 0;
}

.headCell:first-child {
  padding-left: 0.5rem;
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
	.container {
 		margin: 1rem 0 1rem 0;
    min-height: 4em;
	}
  .noTickets {
    margin: 5rem 0;
  }
}

@media only screen and (max-width: 309px) {
  .container {
    margin: 1rem 0 1rem 0;
    min-height: 4em;
  }
  .noTickets {
    margin: 5rem 0;
  }
}
