
.container {
  position: relative;
}

.containerOpen {

}

.input {
  width: 100%;
}

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}


.inputFocused {
  outline: none;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  width: 100%;
  border: 2px solid #999;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 2;
  box-sizing: border-box;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionFirst {

}

.suggestionHighlighted {
  background-color: #ddd;
}


