.basketTotalsContainer {
  margin-top: 1rem;
}

.basketTotalContainer {
  padding-top: 0.5rem;
  clear: both;
  overflow: hidden;
}

.subTotal {
  margin-bottom: 0.5rem;
}

.subTotal p {
  margin: 0.2rem 0;
  display: inline-block;
}

.subTotal p.subTotalFigure {
  display: inline-block;
  float: right;
}

.giftAid p {
  margin: 0.1rem 0;
  display: inline-block;
  font-style: italic;
  font-size: 0.8rem;
}

.giftAid p.giftAidAmount {
  display: inline-block;
  float: right;
}

.basketTotal {
  display: inline-block;
  font-weight: 700;
  font-size: 1.1em;
}

.basketFigure {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 700;
  float: right;
}

.basketTotalfooter {
  font-size: 0.7em;
  float: right;
  clear: both;
}

.addDiscountButton {
  background: #32898f;
  border: none;
  color: #ffffff;
  float: left;
  transition: 0;
  cursor: pointer;
  outline: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  margin-top: 1rem;
  text-transform: uppercase;
}
.addDiscountButton:focus {
  outline: 0;
}
.addDiscountButton:hover {
  color: #28767b;
}

.multiPassTitle {
  padding: 1rem 0 0 0;
  margin-top: 0;
  margin-bottom: 0;
}

.eventTitle {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.removeFees {
  font-size: 0.8em;
  text-transform: capitalize;
  color: #32898f;
  cursor: pointer;
  background: none;
  border: none;
}