
.root {
    clear: both;
  }
  
  .registerForm {
    margin-top: 1rem;
  }
  
  .registerFormForm {
    margin: 1rem 0;
  }
  
  .group {
      margin-bottom: 1rem;
      width: 100%;
  }
  
  .groupInputContainer {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 48%;
    vertical-align: top;
  }
  
  .groupInputContainer:nth-child(2) {
      margin: 0 0 0 2%;
  }
  
  .groupInputContainer input {
    width: 100%;
    padding-left: 2%;
  }
  
  .inputContainer {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .inputContainer input {
    padding-left: 1%;
  }
  
  .emailInput {
    width: 100%;
  }
  
  .nameInput {
    width: 100%;
  }
  
  .error {
    color: #f3545b;
    display: block;
    margin: 0.5rem 0;
  }
  
  .registerHeader h2 {
      margin-top: 0.2rem;
      display: inline-block;
  
  }
  
  .loginBox {
      float: right;
  }
  
  .loginBox p {
      display: inline-block;
      margin-top: 0.3rem;
  }
  
  .loginButton {
    background-color: #32898f;
    margin-left: 0.7rem;
    padding: 0.5rem 1rem;
    color: #fff;
    font-size: 0.8em;
    font-weight: 700;
    border-radius: 2px;
    text-transform: uppercase;
    float: right;
  }
  
  .loginButton:hover {
      background-color: #28767b;
  }
  
  .marketingConsentLabel {
    padding: 1rem 0;
    display: inline-block;
  }
  
  .marketingConsentCheckbox {
    display: inline-block;
    width: auto;
    padding-left: 0.5rem;
  }
  
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .loginBox p {
      margin-top: 0.5rem;
      font-size: 0.9em;
    }
    .registerForm p {
      font-size: 0.9em;
    }
  }
  
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .groupInputContainer {
      /*width: 46.5%;*/
    }
    .inputContainer input {
      /*width: 95.5%;*/
      /*padding-left: 3%;*/
    }
    .groupInputContainer input {
      /*width: 97%;*/
      /*padding-left: 6%;*/
    }
  }
  
  @media only screen and (max-width: 479px) {
    .registerFormForm {
      margin: 1rem 0 2rem 0;
    }
    .groupInputContainer {
      /*width: 46.5%;*/
    }
    .inputContainer input {
      /*width: 95.5%;*/
      padding-left: 3%;
    }
    .groupInputContainer input {
      /*width: 97%;*/
      padding-left: 6%;
    }
    .loginBox p {
      margin-top: 0.5rem;
      font-size: 0.9em;
    }
    .loginButton {
      padding: 0.5rem;
    }
    .marketingConsentLabel {
      font-size: 0.8em;
    }
  }
  