.donationForm {
	width: 100%;
	margin-bottom: 1rem;
	overflow: hidden;
}
.donationForm h4{
  margin-bottom: 1rem;
  margin-top: 0;
}

.donationFormInput {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.donationApplyButton {
  background-color: #32898f;
  padding: 0.725rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 20%;
  float: right;
  text-align: center;
	margin-left: 5%;
}

.donationCancelButton {
	background-color: #ccc;
	padding: 0.725rem 0;
	color: #fff;
	font-weight: 700;
	border-radius: 2px;
	text-transform: uppercase;
	width: 20%;
	float: right;
	text-align: center;
	margin-left: 5%;
}

.donationApplyButton:hover, .donationCancelButton:hover {
	background-color: #28767b;
}

.donationApplyButtonDisabled {
  padding: 0.725rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
  background-color: #ccc;
  cursor: default;
}

.donationApplyButtonDisabled:hover {
  background-color: #ccc;
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
	.donationApplyButton, .donationApplyButtonDisabled, .donationCancelButton {
		padding: 0.65rem 0;
	}
}

@media only screen and (max-width: 309px) {
  .donationApplyButton, .donationApplyButtonDisabled, .donationCancelButton {
    padding: 0.675rem 0;
  }
}
