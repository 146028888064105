.root {
  clear: both;
  margin: 3rem 0 0.5rem 0;
}

.powerBy {
  font-size: 0.8em;
  margin-left: 0;
}

.userInformation {
  float: right;
  font-size: 0.8em;
  margin-top: 0.15rem;
}

.userInformation button {
  text-decoration: underline;
}

.share {
  font-size: 0.7em;
  color: #999;
  border: 1px solid #ccc;
  text-transform: uppercase;
  padding: 0.4rem 0.5rem;
  border-radius: 0.2rem;
  text-align: center;
  width: 70px;
}

.share:hover {
  background: #f1f1f1;
}

.shareIcon {
  margin-right: 0.2rem;
}

@media only screen and (max-width: 309px) {
  .userInformation {
    float: none;
    display: block;
    margin-top: 1.2rem;
  }
}
