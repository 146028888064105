select {
  font-family: 'lato', sans serif;
  font-size: 1.1em;
  width: 100%;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: transparent;
}

.outerWrap {
  padding: 0.5rem;
  clear: both;
  position: relative;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  background-clip: padding-box;
  box-sizing: border-box;
  margin: 0 0 1rem 0;

}

.outerWrap select {
  margin: 0 -15px 0 -30px;
  padding: 0 15px 0 30px;
}

.outerWrap::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  background-image: url(../../images/dropdown.svg);
  pointer-events: none;
}

.outerWrap::before {
  display: inline-block;
  content: '';
  width: 21px;
  height: 15px;
  background: url('../../images/flags.svg');
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.outerWrap.at::before {
  background-position: -165px -10px;
}
.outerWrap.au::before {
  background-position: -196px -10px;
}
.outerWrap.be::before {
  background-position: -227px -10px;
}
.outerWrap.br::before {
  background-position: -351px -10px;
}
.outerWrap.ca::before {
  background-position: -382px -10px;
}
.outerWrap.ch::before {
  background-position: -475px -10px;
}
.outerWrap.cn::before {
  background-position: -41px -35px;
}
.outerWrap.de::before {
  background-position: -134px -35px;
}
.outerWrap.dk::before {
  background-position: -196px -35px;
}
.outerWrap.es::before {
  background-position: -320px -35px;
}
.outerWrap.eu::before {
  background-position: -351px -35px;
}
.outerWrap.fi::before {
  background-position: -382px -35px;
}
.outerWrap.fr::before {
  background-position: -413px -35px;
}
.outerWrap.gb::before {
  background-position: -475px -35px;
}
.outerWrap.hk::before {
  background-position: -41px -60px;
}
.outerWrap.ie::before {
  background-position: -196px -60px;
}
.outerWrap.it::before {
  background-position: -351px -60px;
}
.outerWrap.jp::before {
  background-position: -444px -60px;
}
.outerWrap.lu::before {
  background-position: -258px -85px;
}
.outerWrap.mx::before {
  background-position: -475px -85px;
}
.outerWrap.nl::before {
  background-position: -103px -110px;
}
.outerWrap.no::before {
  background-position: -134px -110px;
}
.outerWrap.nz::before {
  background-position: -165px -110px;
}
.outerWrap.pt::before {
  background-position: -413px -110px;
}
.outerWrap.se::before {
  background-position: -103px -135px;
}
.outerWrap.sg::before {
  background-position: -134px -135px;
}
.outerWrap.us::before {
  background-position: -475px -135px;
}
