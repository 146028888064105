.root {
  margin-top: 0.5rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
}

.root p {
	margin: 0.2rem 0;
	font-size: 0.9em;
}

.basketItemDetails {
	display: inline-block;
	width: 80%;
}

p.basketItemRemove {
	font-size: 0.8em;
	text-decoration: underline;
  cursor: pointer;
}

.ticketPrice {
	float: right;
	display: inline-block;
	width: 20%;
	text-align: right;
}

.faceValue {
	display: inline-block;
}

.basketItemSeat {

}

.basketItemSeat:before {
	content:', ';
}

.basketItemSeat:first-child:before {
	content:'';
}
