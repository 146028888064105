
.loginFormForm {
	clear: both;
}


.submitButton {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  clear: both;
  text-align: center;
  display: block;
}

.submitButton:hover {
	background-color: #28767b;
}

.formError {
  color: #f3545b;
  display: block;
  margin-bottom: 1rem;
  font-size: 0.9em;
}
