
.root {
}

.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1.5rem
}

.backButton a:hover {
  color: #666;
}

.backButton span {
  margin-right: 0.3rem;
}

.pastOrdersTable {
  display: table;
  width: 100%;
}
.pastOrdersTableHeader {
  display: table-header-group;
}
.pastOrdersTableHeaderCell{
  display: table-cell;
  font-weight: 700;
  padding: 0.7rem 0;
}
.pastOrdersTableBody {
  display: table-row-group;
}
.pastOrdersTableRow {
  display: table-row;
}
.pastOrdersTableCell {
  display: table-cell;
  padding: 0.7rem 0;
  border-bottom: 1px solid #ccc;
}
.pastOrdersTableCell:last-child {
  text-align: right;
  padding-left: 0.3rem;
}
.pastOrdersTableCell button {
  font-size: 0.8em;
  font-weight: 700;
  color: #fff;
  background-color: #32898f;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}

.root {
}

.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1.5rem
}

.backButton a:hover {
  color: #666;
}

.backButton span {
  margin-right: 0.3rem;
}

.loginContainer {
  width: 60%;
  margin: 0 auto 2rem auto;
}

.loginHeader h2 {
	margin-top: 0.2rem;
	display: inline-block;
}

.registerBox {
	float: right;
}

.registerBox p {
	display: inline-block;
	margin-top: 0.3rem;
}

.registerButton {
  background-color: #32898f;
  margin-left: 0.7rem;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  float: right;
}

.registerButton:hover {
	background-color: #28767b;
}

.forgotPassword {
  font-size: 0.8em;
  color: #ccc;
  margin-top: 0.5rem;
  display: block;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}
.pagination button{
  flex:0 1 auto;
  font-size: 0.8em;
  font-weight: 700;
  color: #fff;
  background-color: #32898f;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}

.paginationIcon {
  margin-left: 0.3rem;
}

.paginationIconLeft {
  margin-right: 0.3rem;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.backButton {
    	margin-bottom: 1.5rem;
    	margin-top: 0.4rem;
  	}
}

@media only screen and (max-width: 479px) {
	.backButton {
    	margin-bottom: 1.5rem;
    	margin-top: 0.4rem;
  	}
}


@media only screen and (min-width: 480px) and (max-width: 767px) {
  .loginContainer {
    width: 90%;
  }
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
}

@media only screen and (max-width: 479px) {
  .loginContainer {
    width: 100%;
  }
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
  .registerBox p {
    margin-top: 0.5rem;
    font-size: 0.9em;
  }
  .registerButton {
    padding: 0.5rem;
  }
  .pagination button{
      width: 100%;
  }
}
