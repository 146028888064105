
.root {
  clear: both;
}

.totalBox {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 1.5rem;
}

.totalPrice {
  font-size: 1rem;
}

.totalPrice span.totalPriceFigure {
  font-size: 1.2rem;
}

.bookButtonContainer {
  float:right;
}

.ctaContainer{
  float: right;
}

.ctaDivider {
  margin: 0 0.5rem;
}



.bookButton {
  background-color: #32898f;
  padding: 0.75rem 2.5rem;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.bookButton:hover {
  background-color: #28767b;
}

.bookButtonDisabled {
  background-color: #ccc;
  padding: 0.75rem 2.5rem;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.bookButtonDisabled:hover {
  background-color: #ccc;
  cursor: default;
}

.clearFix {
  clear: both;
  content: '';
}

@media only screen and (min-width: 309px) and (max-width: 649px) {
  .totalBox {
    display: block;
    vertical-align: top;
    margin-right: 0;
    text-align: right;
    margin: 0 0 1rem 0;
  }
}

@media only screen and (max-width: 398px) {
  .bookButton {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .bookButtonDisabled {
    width: 100%;
    margin-bottom: 1rem;
  }
  .ctaDivider {
    margin-bottom: 0.5rem;
    display: block;
    text-align: center;
  }
  .bookButtonContainer {
    float:none;
  }
  .ctaContainer{
    float: none;
  }
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
  .bookButton {
    padding: 0.75rem 1.5rem;
  }
  .bookButtonDisabled {
    padding: 0.75rem 1.5rem;
  }
}

@media only screen and (max-width: 309px) {
  .bookButton {
    padding: 0.5rem 0.75rem;
    font-size: 0.9em;
  }
  .bookButtonDisabled {
    padding: 0.5rem 0.75rem;
    font-size: 0.9em;
  }
  .totalPrice span.totalPriceFigure {
    font-size: 1.2em;
  }
  .totalBox {
    display: block;
    vertical-align: top;
    margin-right: 0;
    text-align: right;
    margin: 0 0 1rem 0;
  }
}
