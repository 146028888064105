
.root {
}

.root p:first-child {
	margin-top: 0;
}

h2 {
    margin-top: 0;
  }
