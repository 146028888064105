.formHeader {
  padding: 0 0 0.5em 0;
}

.formHeader h2 {
  margin-top: 0;
  display: inline-block;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
