.root {
  margin: 4rem auto;
  display: block;
  text-align: center;
}

.root p {
  margin-bottom: 2rem;
}

.tryAgainButton {
  background-color: #32898f;
  border: none;
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin-top: 1rem;
  width: 50%;
  clear: both;
  text-align: center;
}

.tryAgainButton:hover {
  background-color: #28767b;
}
