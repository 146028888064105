.form {
	margin: 0.5rem 0 0 0;
}


.confirmButton {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 100%;
  float: left;
  clear: both;
  text-align: center;
}

.confirmButton:hover {
	background-color: #28767b;
}

.confirmButtonDisabled {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 25%;
  float: left;
  clear: both;
  text-align: center;
  background-color: #ccc;
}
.formError{
  color: #f3545b;
  margin: -0.5rem 0 0.75rem 0;
  font-size: 0.9rem;
}

.confirmButtonDisabled:hover {
  background-color: #ccc;
}

.giftAidCheckLabel {
  display: inline-block;
  padding-left: 1.5rem;
  vertical-align: top;
}
.giftAidDeclaration {
  font-size: 0.8rem;
}

.giftAidDeclarationContainer {
  margin-top: 2rem;
}

.giftAidDeclarationBullet {
  font-size: 0.8rem;
}

.giftAidCheckboxContainer {
  display: inline-block;
}

.giftAidCheckbox {
  position: absolute;
}

.giftAidCheckbox input {
  width: auto;
}

.giftAidCheckbox div input {
  width: auto;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.confirmButton {
		width: 100%;
	}
	.confirmButtonDisabled {
		width: 100%;
	}
}

@media only screen and (max-width: 479px) {
	.checkbox input {
		margin: 0.1rem 0 0 0.5rem;
	}
	.confirmButton {
		width: 100%;
	}
	.confirmButtonDisabled {
		width: 100%;
	}
  .giftAidCheckLabel {
    display: inline-block;
    padding-left: 1.3rem;
    font-size: 0.85rem;
    width: 80%;
  }
  .giftAidCheckbox div input {
    margin-bottom: 1.5rem;
  }
}
