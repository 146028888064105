.container {
}

.timesContainer {
  width: 100%;
  display: block;
}
.summaryLozenge {
  display: block;
  color: #33898f;
  background: none;
  margin: 0.25rem;
  line-height: 1.5em;
  font-weight: 700;
  text-align: right;
}
.timeLozenge,
.timeLozengeNoTickets,
.timeLozengeSoldOut {
  font-size: 0.8rem;
  display: block;
  background: #ccc;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  margin: 0.2rem 0;
  line-height: 1.5em;
}
.timeLozengeSoldOut {
  opacity: 0.6;
}

.timeLozenge:last-child:after,
.timeLozengeNoTickets:last-child:after {
  content: none;
}

.timeLozengeOriginal {
}
.timeLozengeVersionOne {
  background: #cf004a;
}
.timeLozengeVersionTwo {
  background: #4398d6;
}
.timeLozengeVersionThree {
  background: #f9aa01;
}

.moreTimesAvailable {
  display: inline-block;
  font-size: 0.8em;
}

.dayDateContainer {
  display: block;
  width: 100%;
  vertical-align: top;
}

.timesContainerMobile {
  display: none;
}

@media only screen and (min-width: 900px) and (max-width: 1044px) {
  .timeLozenge {
    font-size: 0.7rem;
  }
  .timeLozengeNoTickets {
    font-size: 0.7rem;
  }
  .moreTimesAvailable {
    font-size: 0.7rem;
  }
  .summaryLozenge {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 730px) and (max-width: 899px) {
  .timeLozenge {
    font-size: 0.7rem;
  }
  .timeLozengeNoTickets {
    font-size: 0.7rem;
  }
  .moreTimesAvailable {
    font-size: 0.7rem;
  }
  .summaryLozenge {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 729px) {
  /* .timesContainer {
    display: none;
  }
  .timesContainerMobile {
    display: block;
  } */

  .dayDateContainer {
    font-size: 0.7rem;
  }
  .timeLozenge {
    font-size: 0.6rem;
  }
  .summaryLozenge {
    font-size: 0.7rem;
  }
  .timeLozengeNoTickets {
    font-size: 0.6rem;
  }
  .moreTimesAvailable {
    font-size: 0.6rem;
  }
  /* .dot {
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #666;
    position: absolute;
    margin-left: 5%;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
  } */
}

@media only screen and (max-width: 479px) {
  .timesContainer {
    display: none;
  }
  .dayDateContainer {
    display: inline-block;
    width: 15%;
    vertical-align: top;
  }
  .timesContainerMobile {
    display: block;
  }
  .dot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #666;
    position: absolute;
    margin-left: 5%;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
  }
}
