.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1.5rem
}

.backButton a:hover {
  color: #666;
}

.backButton span {
  margin-right: 0.3rem;
}

.panel h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.eventSummary {
  overflow: hidden;
  margin-bottom: 1rem;
}
.purchaseItem {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  overflow: hidden;
}

.purchaseItem:last-child {
  border-bottom: 0;
}

.purchaseRow {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  overflow: hidden;
}

.purchaseRow:last-child {
  border-bottom: 0;
}

.purchaseItem p {
  margin: 0.2rem 0;
  font-size: 0.9em;
}

.purchaseInfo {
  width: 70%;
  display: inline-block;
}

.purchasePricePrice {
  display: inline-block;
  margin-right: 1rem;
}

.purchasePriceAction {
  display: inline-block;
}

.purchaseSeats:before {
  content:', ';
}

.purchaseSeats:first-child:before {
  content:'';
}

.purchasePrice {
  float: right;
  display: inline-block;
  width: 30%;
  text-align: right;
}

.totals {
}

.subTotal {
}

.subTotal p {
  margin: 0.2rem 0;
  display: inline-block;
}

.subTotal p.subTotalFigure {
  display: inline-block;
  float: right;
}

.total {
  margin-top: 0;
  padding-top: 1.5rem;
  clear: both;
}

.totalLabel {
  display: inline-block;
  font-weight: 700;
  font-size: 1.1em;
}

.totalValue {
  display: inline-block;
  font-size: 1.2em;
  font-weight: 700;
  float: right;
}

.totalFooter {
  font-size: 0.7em;
  margin-top: 0;
}


.downloadTicketsContainer {
  margin: 1rem 0;
  width: 100%;
}

.downloadTicketsContainer a {
  width: 100%;
  display: block;
}

.downloadTicketsButton {
  background-color: #32898f;
  padding: 0.5rem 0;
  width: 100%;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
}

.downloadTicketsButton:hover {
  background-color: #28767b;
}

.successGuideText {
  margin-top: 2rem;
}
.orderBreakdownPanel {
  width: 100%;
  float: none;
  margin-top: 0;
  border-left: 0;
  padding-left: 0;
}

.cancelTicketButton {
  font-size: 0.7em;
  font-weight: 700;
  color: #fff;
  background-color: #32898f;
  padding: 0.3rem 0.3rem;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (min-width: 480px) and (max-width: 750px) {
  .purchasePrice {
    width: 40%;
  }
  .purchaseInfo {
    width: 60%;
  }

}


@media only screen and (min-width: 320px) and (max-width: 479px) {

  .downloadTicketsContainer {
    margin-top: 0.8rem;
    font-size: 0.9em;
  }
  .downloadTicketsButton {
    padding: 0.5rem 0;
  }
  .purchasePrice {
    width: 100%;
    float: none;
    text-align: left;
    margin: 0.5rem 0;
  }
  .purchaseInfo {
    width: 100%;
  }
}

@media only screen and (max-width: 319px) {

  .downloadTicketsContainer {
    margin-top: 0.8rem;
    font-size: 0.9em;
  }
  .downloadTicketsButton {
    padding: 0.5rem 0;
  }
  .purchasePrice {
    width: 100%;
    float: none;
    text-align: left;
    margin: 0.5rem 0;
  }
  .purchaseInfo {
    width: 100%;
  }
}
