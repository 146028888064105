.discountForm {
	width: 100%;
	margin-bottom: 1rem;
  }
  
  .inputContainer {
	width: 75%;
	display: inline-block;
	vertical-align: top;
  }
  
  .inputContainer input {
	width: 100%;
	padding: 0.5em;
  }
  
  .discountAppyButtonContainer {
	width: 22%;
	display: inline-block;
	margin-left: 3%;
  }
  
  .discountApplyButton {
	background-color: #32898f;
	padding: 0.725rem 0;
	color: #fff;
	font-weight: 700;
	border-radius: 2px;
	text-transform: uppercase;
	width: 100%;
	float: right;
	clear: both;
	text-align: center;
  }
  
  .discountApplyButton:hover {
	background-color: #28767b;
  }
  
  .discountApplyButtonDisabled {
	padding: 0.725rem 0;
	color: #fff;
	font-weight: 700;
	border-radius: 2px;
	text-transform: uppercase;
	width: 100%;
	float: right;
	clear: both;
	text-align: center;
	background-color: #ccc;
	cursor: default;
  }
  
  .discountApplyButtonDisabled:hover {
	background-color: #ccc;
  }
  
  @media only screen and (min-width: 310px) and (max-width: 479px) {
	.discountApplyButton,
	.discountApplyButtonDisabled {
	  padding: 0.65rem 0;
	}
  }
  
  @media only screen and (max-width: 309px) {
	.discountApplyButton,
	.discountApplyButtonDisabled {
	  padding: 0.675rem 0;
	}
  }
  