.countdown {
	color: #999;
}

p.countdownMessage {
  font-size: 0.8em;
  display: inline-block;
  margin: 0 0.3rem 0 0;
  padding: 0;
}

.countdownNumber {
	font-size: 1.3em;
	display: inline-block;
}

@media only screen and (max-width: 479px) {
	p.countdownMessage {
  		display: none;
	}
	.countdownNumber {
		font-size: 1em;
	}
}
