
.container {
  margin: 2rem 0 1rem 0;
}

.ticketTable {
  width: 100%;
  border-collapse: collapse;
}

.ticketTable tbody {
  font-size: 1rem;
  color: #494949;
}

.ticketTable td {
  /*padding: 1rem 0;*/
  font-size: 1.1em;
  border-bottom: 1px solid #ddd;
}

.noTickets {
  margin: 5rem 0;
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
  .container {
    margin: 1rem 0 1rem 0;
  }
  .ticketTable td {
    font-size: 1em;
  }
  .noTickets {
    margin: 5rem 0;
  }
}

@media only screen and (max-width: 309px) {
  .container {
    margin: 1rem 0 1rem 0;
  }
  .ticketTable td {
    font-size: 0.9em;
  }
  .noTickets {
    margin: 5rem 0;
  }
}


