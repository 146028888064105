
.root {
}
.collectionHeader {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #666;
  margin: 0;
}

@media only screen and (max-width: 479px) {
  .collectionHeader {
      font-size: 0.8em;
      text-transform: uppercase;
      color: #666;
      margin: 0;
  }
}
