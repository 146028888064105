.root {

}

.title {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #666;
  margin: 0;
}

.eventRow {
  display: block;
  width: 100%;
  clear: both;
  margin-bottom: 0.5rem;
  padding: 1rem 0.5rem;
  border-bottom: 1px #cccccc solid;
  overflow: hidden;
}

.eventTitle {
  font-size: 1em;
  text-transform: uppercase;
  color: #32898f;
  cursor: pointer;
  float: left;
  display: inline-block;
  width: 90%;
}
.eventImage {
  width: 10%;
  float: left;
}

.eventImage img {
  width: 45px;
  height: 40px;
  float:left;
}
