
/* dark background overlay */
.root {
  background-color: rgba(255,255,255,0.99);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;
  border: 1px solid #ccc;
  color: #666;
}

.modalContent {
  overflow: visible;
  /*overflow-y: auto;*/
  position: absolute;
  padding: 1% 1%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-transform: translate3d(0, 0, 0);
}

.modalClose {
  width: 5%;
  position: absolute;
  top: 1em;
  left: 1em;
}

.modalClose img {
  width: 22px;
  height: 22px;
  border: 0;
}

.modalClose a.closeView {
  text-decoration: none;
}

.modalClose a.closeView:visited, {
  text-decoration: none;
}

.modalMain {
  width: 95%;
  display: block;
  margin: auto;
}



@media only screen and (min-width: 1440px) {

}

@media only screen and (min-width: 992px) and (max-width: 1290px) {

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .modalClose {
    width: 10%;
  }
  .modalMain {
    width: 90%;
  }
  .modalContent {
    padding: 2% 2%;
  }
  .modalClose img {
    width: 17px;
    height: 17px;
  }
}

@media only screen and (max-width: 479px) {
  .modalClose {
    width: 10%;
  }
  .modalMain {
    width: 90%;
  }
  .modalContent {
    padding: 2% 2%;
  }
  .modalClose img {
    width: 17px;
    height: 17px;
  }
}
