.root {
  padding: 0 1rem 0 0;
  overflow: hidden;
}
.bookButtonContainer {
  float: right;
  padding-top: 0.2rem;
  margin-top: 1rem;
  clear: both;
}

.bookButton {
  background-color: #32898f;
  padding: 0.75rem 2.5rem;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;

}

.bookButton:hover {
  background-color: #28767b;
}

.bookButtonDisabled {
  background-color: #ccc;
  padding: 0.75rem 2.5rem;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.bookButtonDisabled:hover {
  background-color: #ccc;
  cursor: default;
}

@media only screen and (max-width: 479px) {
  .bookButtonContainer {
    width: 100%;
  }
  .bookButton {
    width: 100%;
  }

}
