.formRowCheck {
  position: relative;
  display: block;
}
.formRowInlineCheck {
  display: inline-flex;
  justify-content: center;
  margin-right: .75rem;
  padding-left: 0;
  margin-bottom: 0.5rem;
  align-items: center;
}

.formFieldCheck {
  position: absolute;
  margin-top: .2rem;
  margin-left: -1.25rem;
  width: auto;
}
.formFieldCheckInline {
  position: static;
  margin: 0;
  margin-right: .3125rem;
}

.formLabelCheck {
  display: inline-block;
  margin-bottom: .5rem;
}
.formLabelCheckInline {
  margin: 0;
}

.checkboxGroup{
  margin-bottom: 1rem;
}

.formInputError {
  display: inline-block;
  border: 2px solid #f3545b;
}

.formError {
  color: #f3545b;
  margin: -0.5rem 0 0.75rem 0;
  font-size: 0.9rem;
  display: block;
}

.checkBoxTitle {
	display: block;
	font-weight: 700;
  margin-bottom: 0.5rem;
}
