.root {

}
.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1rem
}

.giftAidAddressName {
	color: #666;
}