
.root {
}

.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1.5rem
}

.backButton a:hover {
  color: #666;
}

.backButton span {
  margin-right: 0.3rem;
}

.forgotPasswordContainer {
	width: 60%;
  margin: 2rem auto 4rem auto;
}

.loginButtonContainer {
	overflow: hidden;
}

.loginButton {
	background-color: #32898f;
  	padding: 0.75rem 0;
  	color: #fff;
  	font-weight: 700;
  	border-radius: 2px;
  	text-transform: uppercase;
  	width: 100%;
  	float: right;
  	clear: both;
  	text-align: center;
}

.loginButton:hover {
	background-color: #28767b;
}


@media only screen and (min-width: 480px) and (max-width: 767px) {
	.forgotPasswordContainer {
		width: 90%;
	}
	.backButton {
    	margin-bottom: 1.5rem;
    	margin-top: 0.4rem;
  	}
}

@media only screen and (max-width: 479px) {
	.forgotPasswordContainer {
		width: 100%;
	}
	.backButton {
    	margin-bottom: 1.5rem;
    	margin-top: 0.4rem;
  	}
}
