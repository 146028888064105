
.root {
  border: none;
  font-size: 1em;
  font-family: 'lato', sans serif;
  transition: 0;
  -webkit-transition: 0;
  cursor: pointer;
  outline: 0;
}

.root:focus {
	outline: 0;
}
