.visibleLabel {
	
}

.formInput {
	display: inline-block;
}

.formInputError {
  display: inline-block;
  border: 2px solid #f3545b;
}

.formError {
  color: #f3545b;
  margin: 0.25rem 0 0.75rem 0;
  font-size: 0.9rem;
  display: block;
}