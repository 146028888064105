.backButton {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1rem
}

.backButton a:hover {
  color: #666;
}

span.backButton {
  margin-right: 0.3rem;
}

.root {
}

.error {
  color: #f3545b;
  display: block;
  margin: 0 0 1rem 0;
}

.row {
  clear: both;
}

.orderPanel {
  width: 100%;
}

.orderPanel h4 {
  margin-top: 0;
}

.paymentPanel {
  width: 100%;
  margin-top: 2rem;
}

.paymentHeader h3 {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.paymentHeader img {
  width: 200px;
  float: right;
  height: 28.5px;
}

.paymentDetails {
  margin: 1.5rem 0 0.5rem 0;
}

.selectedCard {
  display: inline-block;
}

.selectedCardBrand {
  margin: 0 0.5rem;
  display: inline-block;
}

.changePaymentButton {
  float: right;
  text-decoration: underline;
  font-size: 0.9em;
}

.orderButton {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin-top: 1rem;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
}

.orderButton:hover {
  background-color: #28767b;
}

.orderButtonDisabled {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin-top: 1rem;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
  background-color: #ccc;
}

.orderButtonDisabled:hover {
  background-color: #ccc;
}

.clearFix {
  clear: both;
}
.formError {
  color: #f3545b;
  display: block;
  margin: 0.5rem 0;
  font-size: 0.9em;
}
.confirmationMessage{
  font-size: 0.8em;
}

.giftAidCheckbox {
  width: auto;
  margin-right: 0.5rem;
}
.giftAidCheckboxLabel {
  display: inline-block;
  width: 90%;
  vertical-align: top;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 900px) and (max-width: 1020px) {
  .changePaymentButton {
    float: none;
    display: block;
    margin-top: 0.5rem;
  }
  .paymentDetails {
    margin: 1.5rem 0 2rem 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .changePaymentButton {
    float: none;
    display: block;
    margin-top: 0.5rem;
  }
  .paymentDetails {
    margin: 1.5rem 0 2rem 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
  .orderPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .paymentPanel {
    width: 100%;
    float: none;
    margin-top: 2rem;
  }
  .orderButton, .orderButtonDisabled {
    margin-bottom: 1rem;
  }
  .paymentDetails {
    margin: 0.5rem 0;
  }
}

@media only screen and (min-width: 310px) and (max-width: 479px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
  .orderPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .paymentPanel {
    width: 100%;
    float: none;
    margin-top: 2rem;
  }
  .orderButton, .orderButtonDisabled {
    margin-bottom: 1rem;
  }
  .paymentDetails {
    margin: 0.5rem 0;
  }
  .changePaymentButton {
    float: none;
    display: block;
    margin-top: 0.5rem;
  }
  .paymentHeader img {
    width: 140px;
    float: right;
    height: 20px;
  }
  .giftAidCheckboxLabel {
    font-size: 0.8rem;
    width: 80%;
    vertical-align: text-top;
  }
}

@media only screen and (max-width: 309px) {
  .backButton {
    margin-bottom: 1.5rem;
    margin-top: 0.4rem;
  }
  .orderPanel {
    width: 100%;
    float: none;
    border-right: 0;
    padding-right: 0;
  }
  .paymentPanel {
    width: 100%;
    float: none;
    margin-top: 2rem;
  }
  .orderButton, .orderButtonDisabled {
    margin-bottom: 1rem;
  }
  .paymentDetails {
    margin: 0.5rem 0;
  }
  .changePaymentButton {
    float: none;
    display: block;
    margin-top: 0.5rem;
  }
  .paymentHeader img {
    width: 110px;
    float: right;
    height: 16px;
  }
  .giftAidCheckboxLabel {
    font-size: 0.8rem;  
    width: 80%;
    vertical-align: text-top;
  }
}
