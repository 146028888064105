.donationBlock{
  margin-bottom: 1rem;
}
.donationBlock h4{
  margin-bottom: 1rem;
  margin-top: 0;
}
.donationButton {
  width: 20%;
  font-size: 1em;
  padding: 0.75rem 0;
  color: #b3b3b3;
  border: 1px solid #b3b3b3;
  border-radius: 0px;
  border-right: 0;
  text-transform: uppercase;
  cursor: pointer;
}
.donationButtonLast{
  border-right: 1px solid;
}

.donationButtonSelected {
  background-color: #32898f;
  border-color: #32898f;
  color: #fff;
}
.donationButton:hover {
  background-color: #32898f;
  color:#fff;
}
.donationButton:focus {
  outline:0;
}

.donationButtonDisabled {
  background-color: #ccc;
  padding: 0.75rem 2.5rem;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.donationButtonDisabled:hover {
  background-color: #ccc;
  cursor: default;
}

.donationText {
  font-size: 0.8rem;
}
