
.add, .remove {
  display: inline-block;
  background-color: #32898f;
  text-decoration: none;
  margin-left: 1.5rem;
  padding: 0.2rem 0.5rem;
  color: #fff;
  text-align: center;
  border-radius: 0.2rem;
  font-weight: 700;
}

.add:hover {
  background: #28767b;
}

.remove {
  margin-left: 0;
  margin-right: 1.5rem;
}

.remove:hover {
  background: #28767b;
}

.controlsColumn {
  text-align: right;
  width: 20%;
  padding: 1rem 0;
  vertical-align: top;
}

.controlsColumn span.soldOut {
  margin-right: 7%;
}

.addDisabled, .addDisabled:hover {
  display: inline-block;
  text-decoration: none;
  margin-left: 1.5rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
  border-radius: 0.2rem;
  font-weight: 700;
  background-color: #ccc;
  cursor: default;
  color: #ffffff;
}

.removeDisabled, .removeDisabled:hover {
  display: inline-block;
  text-decoration: none;
  margin-right: 1.5rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
  border-radius: 0.2rem;
  font-weight: 700;
  background-color: #ccc;
  cursor: default;
  color: #ffffff;
}

.total {
  margin-right: 5px;
}

.fees {
  font-size: 0.7em;
  color: #999;
  margin-left: 0.3rem;
}

.descriptionColumn {
  width: 60%;
  padding: 1rem 0.5rem 1rem 0;
  vertical-align: top;
}

.description {
}
.descriptionDetail {
  font-size: 0.8em;
  display: block;
}
.pricesColumn {
  width: 20%;
  padding: 1rem 0;
  vertical-align: top;
}

.descriptionTooltipMobile {
    display: none;
  }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .descriptionColumn {
    width: 50%;
  }
  .controlsColumn {
    width: 25%;
  }
  .pricesColumn {
    width: 25%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .add, .addDisabled, .addDisabled:hover {
    margin-left: 1rem;
  }
  .remove, .removeDisabled, .removeDisabled:hover  {
    margin-right: 1rem;
  }
  .descriptionColumn {
    width: 40%;
  }
  .controlsColumn {
    width: 30%;
  }
  .pricesColumn {
    width: 30%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .descriptionTooltip {
    display: none;
  }
  .descriptionTooltipMobile {
    display: block;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}

@media only screen and (max-width: 319px) {
  .add, .addDisabled, .addDisabled:hover {
    margin-left: 1rem;
  }
  .remove, .removeDisabled, .removeDisabled:hover  {
    margin-right: 1rem;
  }
  .descriptionColumn {
    width: 30%;
  }
  .controlsColumn {
    width: 30%;
  }
  .pricesColumn {
    width: 15%;
  }
  .fees {
    display: block;
    margin-left: 0;
  }
  .descriptionTooltip {
    display: none;
  }
  .descriptionTooltipMobile {
    display: block;
  }
  .controlsColumn span.soldOut {
    margin-right: 13%;
  }
}
