.form {
	margin: 0.5rem 0 0 0;
}

.formDescription {
  margin: 0.5em 0;
}
.shortField {
  width: 48%;
  float: left;
}

.floatRight {
	float: right;
}

.reviewButton {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 100%;
  float: left;
  clear: both;
  text-align: center;
}

.reviewButton:hover {
	background-color: #28767b;
}

.reviewButtonDisabled {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0.5rem 0 1.5rem 0;
  width: 25%;
  float: left;
  clear: both;
  text-align: center;
  background-color: #ccc;
}
.formError{
  color: #f3545b;
  margin: -0.5rem 0 0.75rem 0;
  font-size: 0.9rem;
}

.reviewButtonDisabled:hover {
  background-color: #ccc;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.reviewButton {
		width: 100%;
	}
	.reviewButtonDisabled {
		width: 100%;
	}
}

@media only screen and (max-width: 479px) {
	.checkbox input {
		margin: 0.1rem 0 0 0.5rem;
	}
	.reviewButton {
		width: 100%;
	}
	.reviewButtonDisabled {
		width: 100%;
	}
	.shortField {
		width: 100%;
		float: left;
	}

	.floatRight {
		float: left;
	}
}
