.clear {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1em;
    margin: auto;
    height: 1em;
    text-align: center;
    color:#ccc;
    cursor: pointer;
}
.error input {
  border: 2px solid #f3545b;
}
