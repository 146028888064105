.seatingRoot {
  height:350px;
  margin: 1.75rem 0;
  border: 1px solid;
  border-color: #000;
  padding: 1rem;
  border-radius: 0.2rem;
}

.seatPrice {
	padding: 1rem;
}