.root {
}

.dateBarRoot {
  border-bottom: 1px solid #ddd;
  margin-top: 1.25rem;
}

.changeDate {
  margin-left: 0.5rem;
  color: #999;
  display: inline-block;
}

.changeDate:hover {
  color: #666;
}

.changeDate span {
  margin-right: 0.3rem;
}

.changeDateIcon {
  margin-right: 0.3rem;
}

.date {
  padding: 1rem;
  display: inline-block;
  color: #999;
}

.date button:hover {
  color: #666;
}

.dateSelected {
  padding: 1rem;
  display: inline-block;
  font-weight: bold;
  border-bottom: 3px solid #32898f;
  color: #666;
}

.dateSelected a {
}

.timeBarRoot {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.time,
.timeSelected,
.timeWithoutTickets,
.timeSoldOut {
  text-decoration: none;
  border: 1px solid #32898f;
  padding: 0.5rem 0.25rem;
  color: #32898f;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  font-size: 0.75rem;
  flex: 0 0 120px;
}
.endTime {
}

.timeSoldOut {
  text-decoration: line-through;
}
.timeSelected {
  background-color: #32898f;
  font-weight: 700;
  border-color: #32898f;
  color: #ffffff;
}
.timeSelected--hover {
  color: #ffffff;
}

.timeWithoutTickets {
  background-color: #ececec;
  border-color: #ececec;
}

.time:hover {
  background-color: #32898f;
  color: #ffffff;
}

.time:last-child,
.timeSelected:last-child {
  margin-right: 0;
}

.timeWithoutTickets {
  background-color: #ccc;
}

.bookingContainer {
  float: right;
  font-size: 0.8em;
  color: #999;
  margin: 1rem 1rem 0 0;
}

.showBasket,
.showBasketEmpty {
  float: right;
  font-size: 0.8em;
  color: #999;
  margin-top: 0.35rem;
  font-weight: 700;
}

.bookingContainer p,
.showBasket p,
.showBasketEmpty p {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.showBasket button,
.showBasketEmpty button {
  outline: none;
  position: relative;
}

.showBasketEmpty button {
  pointer-events: none;
  cursor: default;
}

.basketIcon {
  font-size: 2.5em;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.basketNotification {
  color: #fff;
  background: #ff0000;
  padding: 0.1em 0.45em;
  border-radius: 50%;
  position: absolute;
  right: -0.5em;
}

@media only screen and (max-width: 479px) {
  .date,
  .dateSelected {
    padding: 0.75rem;
  }

  span.changeDate {
    margin-left: 1rem;
  }

  .timeBarRoot {
    margin-top: 0.5rem;
  }

  .time,
  .timeSelected,
  .timeWithoutTickets {
    padding: 0.5rem 0;
  }
  .endTime {
    width: 150px;
  }

  .bookingContainer a {
    font-size: 1.5em;
  }

  .bookingContainer p {
    display: none;
  }

  .bookingContainer {
    margin: 0.6rem 0.5rem 0 0;
  }
  .showBasket button,
  .showBasketEmpty button {
    font-size: 1.5em;
  }
  .showBasket,
  .showBasketEmpty {
    margin-top: 0.2rem;
  }
  .basketIcon {
    font-size: 1.5em;
    position: relative;
  }
  .basketNotification {
    font-size: 0.7em;
  }
}

/* Transition group style for modal popup
 *
 * see: https://facebook.github.io/react/docs/animation.html
 *
 * */

.modalTransition {
}

.modalTransition-appear {
  opacity: 0.01;
}

.modalTransition-appear.modalTransition-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.modalTransition-enter {
  opacity: 0.01;
}

.modalTransition-enter.modalTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.modalTransition-leave {
  opacity: 1;
}

.modalTransition-leave.modalTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
