.root {
  float: right;
  position: absolute;
  right: 2%;
}

@media only screen and (max-width: 479px) {
  .root {
    right: 6%;
  }
}

@media only screen and (max-width: 309px) {
  .root {
    padding-top: 0.5rem;
  }
}
