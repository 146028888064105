.formHeader {
  padding: 0.5rem 0 0 0;
}

.formHeader h2 {
  margin-top: 0.2rem;
  display: inline-block;
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.row {
  width: 100%;
  padding-bottom: 1rem;
}

.confirmAndPayButton  {
  background-color: #32898f;
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
}

.confirmAndPayButton:hover {
	background-color: #28767b;
}

.disabledButton {
  padding: 0.75rem 0;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%;
  float: right;
  clear: both;
  text-align: center;
  background-color: #ccc;
  cursor: default;
}

.disabledButton:hover {
  background-color: #ccc;
}

.confirmAndPayButton, .disabledButton {
    margin-bottom: 0.5rem;
  }


@media only screen and (min-width: 480px) and (max-width: 767px) {

  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 310px) and (max-width: 479px) {

  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }

}

@media only screen and (max-width: 309px) {
  .confirmAndPayButton, .disabledButton {
    margin-bottom: 1rem;
  }
}
